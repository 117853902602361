import { Injectable, isDevMode } from "@angular/core";
import { User } from "./user";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ProgressService } from "./progress.service";
import { SpinnerService } from "./spinner.service";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AnalyticsService } from "./analytics.service";
import { Platform } from '@ionic/angular';
import { Onboarding } from "./onboarding";
import { User as fbAuthUser } from "firebase/auth";
import { take } from "rxjs/operators";

/**
 * Service that handles other user-related actions within our own backend.
 */
@Injectable({
    providedIn: "root"
})
export class UserService {
    public user?: User;
    public user$ = new ReplaySubject<User | null | {role: "anon"}>(1);
    public fireUser?: fbAuthUser;

    constructor(
        public httpClient: HttpClient, 
        public readonly auth: AngularFireAuth,
        public progressService: ProgressService,
        public spinnerService: SpinnerService,
        public readonly router: Router,
        public authService: AuthService,
        public analyticsService: AnalyticsService,
        public platform: Platform,
        ) {
        if (isDevMode()) {
            (window as any).userService = this;
        }
     }

    /**
     * Checks if the user with this username exists in database.
     * @returns boolean if the username is already taken.
    */
    public async doesUserExist(uid: string): Promise<boolean> {
        return await this.httpClient.get<boolean>(`user/${uid}`).toPromise();
    }

    /**
     * Checks if the user with this username exists.
     * @returns boolean if the username is already taken.
     */
    public async doesUsernameExist(username: string): Promise<boolean> {
        return await this.httpClient.get<boolean>(`user/username/${username}`).toPromise();
    }

    /**
     * Checks if a user with this email exists.
     * @returns boolean if the email is already taken.
     */
    /*public async doesEmailExist(username: string): Promise<boolean> {
        return await this.httpClient.get<boolean>(`user/email/${username}`).toPromise();
    }*/

    /**
     * Creates a user in out custom backend.
     * @param user - The firebase-user to create a user-object for.
     * @param username - the username this user should have.
     */
    public async createUserInBackend(user: fbAuthUser, username: string, age: number, onboarding?: Onboarding, promocode?: string /*fname: string, lname: string,*/): Promise<User> {
        this.user = await this.httpClient.post<User>("user",
            { uid: user.uid, username, age: age, photoURL: user.photoURL, role: "User", points: 0, lifes: 5, onboarding: onboarding, promocode: promocode}).toPromise();
        this.user$.next(this.user);
        return this.user;
    }

    /**
     * Sets the current user for the app.
     * @param user - the signed-in user to grab the database object for or the database object itself.
     */
    public async setCurrentUser(user: fbAuthUser | User | null): Promise<void> {
        if (!user) {
            this.user$.next(undefined);
            return;
        }
        if ("isAnonymous" in user && user.isAnonymous) {
            this.user$.next({role: "anon"});
            return;
        }
        if ("username" in user) {
            this.user = user;
            this.user$.next(this.user);
            return;
        }
        await this.spinnerService.presentLoading();
        const dbUser = await this.httpClient.get<User>(`user/${user.uid}`).toPromise();
        if (dbUser) {
            this.user = dbUser;
            await this.setSkills();
            console.log("User fetched");
            this.user$.next(this.user);
            await this.analyticsService.setIdentity(this.user.uid);
            await this.analyticsService.branchSetReqMetData();
            console.log(dbUser)
        } else if (!dbUser && await this.doesFireUserExist(user)) {
            console.log(`User with id:  ${user.uid} could not be found in db BUT in Firebase!`);
        }
        else {
            this.user$.next(undefined);
        }
        await this.spinnerService.dismissLoading();
    }

    public async getUserApi(): Promise<User>
    {
        return await this.httpClient.get<User>(`user`).toPromise();
    }

    public async getUser(): Promise<User>
    {
        return this.user!;
    }

    public async getAllUsers(): Promise<Array<User>>
    {
        return await this.httpClient.get<Array<User>>(`user/all`).toPromise();
    }

    public async setSkills(): Promise<void>
    {
        let promise = this.progressService.getSkillsByUid()
            .then(function data(skills) {
              return skills;
            });
        this.user!.skills = await promise;
    }
    
    /**
     * Updates the user in our database.
     */
    public async updateUser(userUpdate: Partial<User>, fireUser?: fbAuthUser): Promise<User | void> {
        if (fireUser || this.user) {
            return await this.httpClient.put<User>(`user/${(fireUser! || this.user).uid}`, userUpdate).toPromise();
        }
        console.log("User to update was not provided.");
    }

    /**
    * Delete user from database (users + skills collections)
    */
    public async deleteUserFromDB(): Promise<User>
    {
        return await this.httpClient.delete<User>(`user`).toPromise();
    }

    /**
    * Initiate a permanently deletion of an account from Firebase & database
    */
    public async deleteAccount()
    {
        //User is currently logged in
        if(this.auth.user)
        {
            this.auth.authState.subscribe(user => {
                this.fireUser = user;
            });
            if(this.fireUser)
            {
                if(!this.fireUser.isAnonymous)
                {
                    await this.deleteUserFromDB();
                }
                await this.fireUser!.delete().then(async () => {
                    if(this.platform.is("capacitor"))
                    {
                        await this.analyticsService.trackEvent("Delete Account");
                        await this.analyticsService.signOut();
                    }
                    await this.auth.signOut();
                    this.user = undefined;
                    localStorage.removeItem("auth_token");
                    this.router.navigate(["start"], {replaceUrl: true});
                })
                .catch((err) => {
                    if(err.code == "auth/requires-recent-login")
                    {
                        this.router.navigateByUrl("login/reauth");
                    }
                });
            }
        }
    }

    /**
    * Checks if user exists in firestore
    */
    public async doesFireUserExist(user: fbAuthUser): Promise<boolean>
    {
        return this.auth.authState.pipe(take(1)).toPromise().then((authUser) => {
            return authUser?.uid === user.uid;
          }).catch(() => false);
    }

    public isAdmin(): boolean {
        return this.user?.role === "Admin";
    }

    public getPromocode(): string {
        return this.user!.promocode; 
    }
}

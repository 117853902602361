import { enableProdMode, /*isDevMode*/ } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { defineCustomElements } from "@teamhive/lottie-player/loader";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import posthog from "posthog-js";
/*import LogRocket from 'logrocket';
import { Capacitor } from "@capacitor/core";*/

declare var cordova;

if (environment.production) {
  enableProdMode();
}
defineCustomElements(window);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

posthog.init(
  environment.POSTHOG_KEY!,
  {
    api_host: environment.POSTHOG_HOST,
    person_profiles: 'always',
    autocapture: false,
    capture_pageview: true,
    capture_pageleave: true,
    session_recording: {
      recordBody: true,
      recordCrossOriginIframes: true,
      recordHeaders: true,
      inlineStylesheet: true,
      maskInputOptions: {
        password: true,
        email: false,
        textarea: false,
        date: false,
        text: false,
        search: false
      },
    },
    disable_surveys: false,
    debug: false,
    mask_all_text: false,
    mask_all_element_attributes: false,
  }
)

//posthog.startSessionRecording();